import React from 'react';
import { Link } from 'react-router-dom';
import './header.css'


export const Header = () => {
  const logo: string = require("../../../assets/logo.svg").default;
  return (

    <div data-testid={"header"}  className='header'>
      <Link to="/" className='text'><img src={logo} alt="logo Spb" className='image' /> </Link>
    </div>

  )
}

export default Header;