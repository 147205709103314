import React from "react";
import { Form, Formik } from "formik";
import {
  Reclamation,
  relationSPBSchema,
  TypeRelation,
} from "modules/reclamation/model/reclamation";
import { FormulaireSubmit } from "./formulaire-submit";
import { TextInput } from "shared/components/text-input/text-input";
import { SpbButtonSelect } from "shared/components/spb-button-select/spb-button-select";
import Men from "assets/avatar.png";
import Menchek from "assets/avatarChecked.png";
import ForClient from "assets/avatarPourClient.png";
import ForClientchek from "assets/avatarPourClientChek.png";
import Client from "assets/client.png";
import ClientCheck from "assets/clientCheck.png";


export interface IFormulaireStep1Props {
  reclamation: Reclamation;
  submit: (reclamation: Reclamation) => void;
  cancel: () => void;
}

export const FormulaireStep1: React.FC<IFormulaireStep1Props> = ({
  reclamation,
  submit,
  cancel,
}) => {
  return (
    <div>
      <div className="content-wrap">
        <div className="container">
          <div className="boxLinkP">
            <div className="textLink">Réclamation / Formulaire</div>
          </div>
          <div className="textTitre">Votre relation avec SPB</div>

          <Formik
            initialValues={reclamation.relationSPB}
            validationSchema={relationSPBSchema}
            onSubmit={(values) => {
              submit({ ...reclamation, ...{ relationSPB: { ...values } } });
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => {
              //console.log(values, errors);
              return (
                <div>
                  <Form>
                    <div className="boxButton">
                      <div className="row justify-content ">
                        <div className="col-md-4 b ">
                          <SpbButtonSelect
                            name="Je suis un client"
                            avatar={Client}
                            avatarChek={ClientCheck}
                            selected={
                              values.typeRelation === TypeRelation.Client
                            }
                            onClick={() =>
                              setFieldValue("typeRelation", TypeRelation.Client)
                            }
                          />
                        </div>
                        <div className="col-md-4 b">
                          <SpbButtonSelect
                            avatar={Men}
                            avatarChek={Menchek}
                            name="Je ne suis pas/plus un client"
                            selected={
                              values.typeRelation === TypeRelation.NoClient
                            }
                            onClick={() =>
                              setFieldValue(
                                "typeRelation",
                                TypeRelation.NoClient
                              )
                            }
                          />
                        </div>
                        <div className="col-md-4 b">
                          <SpbButtonSelect
                            name="J'interviens pour un client"
                            avatar={ForClient}
                            avatarChek={ForClientchek}
                            selected={
                              values.typeRelation === TypeRelation.Representant
                            }
                            onClick={() =>
                              setFieldValue(
                                "typeRelation",
                                TypeRelation.Representant
                              )
                            }
                          />
                        </div>
                      </div>
                      {errors.typeRelation && (
                        <span className="non-valid">{errors.typeRelation}</span>
                      )}
                    </div>

                    <div className="row g-3 ">
                      <div className="col-md-6">
                  
                   
  <TextInput
                          label="Société auprès de laquelle vous avez souscrit votre contrat d'assurance :"
                          autoComplete="off"
                          type="text"
                          name="contractSociete"
                          placeholder="ex : Cdiscount"
                          initialValue={values.contractSociete}
                          touched={touched.contractSociete}
                          handleChange={handleChange}
                          error={errors.contractSociete}
                        />
                        
                      </div>
                    </div>
       
                    <div className="row g-3 boxRow align-items-end">
                      <div className="col-md-3 ">
                        <TextInput
                          label="Nom de votre contrat d'assurance :"
                          autoComplete="off"
                          type="text"
                          name="nomContrat"
                          placeholder="ex : 60 jours / 100nuits"
                          initialValue={values.nomContrat}
                          touched={touched.nomContrat}
                          handleChange={handleChange}
                          error={errors.nomContrat}
                        />
                      </div>
                      <div className="col-md-3 offset-md-1">
                        <TextInput
                          label="Numéro de votre adhésion :"
                          autoComplete="off"
                          type="text"
                          name="numAdhesion"
                          placeholder="ex : 52012055"
                          initialValue={values.numAdhesion}
                          touched={touched.numAdhesion}
                          handleChange={handleChange}
                          error={errors.numAdhesion}
                        />
                      </div>

                      <div className="col-md-3 offset-md-1">
                        <TextInput
                          label="Numéro de dossier de sinistre :"
                          autoComplete="off"
                          type="text"
                          name="numSinistre"
                          placeholder="ex : 52012055"
                          initialValue={values.numSinistre}
                          touched={touched.numSinistre}
                          handleChange={handleChange}
                          error={errors.numSinistre}
                        />
                      </div>
                    </div>
                    <FormulaireSubmit previous={() => cancel()} />
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
