import React from 'react';
import { Link } from "react-router-dom"

export const MentionsLegales = () => {

    return (
        <div>
            <div className='content-wrap'>
                <div className='container'>

                    <div className='boxLink'>
                        <Link to="/" className='textLink'>Accueil</Link>
                        <span className='textLink'> / Mentions légales</span><br />
                    </div>
                    <div className='boxTitreT'>
                        <span className='textTitre'>EDITEUR DU SITE & DES SERVICES</span> <br />
                    </div>
                    <div className='textParagraph'>
                        <div className='textParagraph'>SPB<br />
                            Société par Actions Simplifiée au capital de 1 000 000 euros,<br />
                            Immatriculée au Registre du Commerce et des Sociétés (RCS) du Havre sous le n°305 109 779 et à l’ORIAS sous le numéro 07 002 642<br />
                            en qualité de courtier en assurances,<br />
                            Siège social : 71, quai Colbert - 76095 LE HAVRE Cedex</div>
                    </div>
                    <div className='boxTitre4'>
                        <div className='textParagraph'>Directeur de la publication : Jean-Marie GUIAN,Président</div>
                    </div>
                    <div className='textParagraph'>Contact :<br />
                        <span>• </span> <span className='text2'>Téléphone : 02.32.74.20.20</span><br />
                        <span>•</span> <span className='text2'> Courriel : reclamations@spb.eu </span>
                    </div>
                    <div className='boxTitre4'>
                        <div className='textParagraph'>SPB exerce sous le contrôle de l’Autorité de Contrôle Prudentiel et de Résolution :<br />
                            ACPR - 4, Place de Budapest – CS 92459 – 75439 Paris Cedex 09.</div>
                    </div>

                    <div className='boxTitreT'>
                        <span className='textTitre'>HEBERGEUR DU SITE ET DES SERVICES</span><br />
                    </div>

                    <div className='textParagraph'>SPB<br />
                        Société par Actions Simplifiée au capital de 1 000 000 euros,<br />
                        Immatriculée au Registre du Commerce et des Sociétés (RCS) du Havre sous le n°305 109 779 et à l’ORIAS sous le numéro 07 002 642<br />
                        en qualité de courtier en assurances,<br />
                        Siège social : 71, quai Colbert - 76095 LE HAVRE Cedex</div>

                    <div className='boxTitreT1'>
                        <span className='textTitre'>CREDIT</span>
                    </div>
                    <div className='textParagraph'>
                        <span >Icônes par FLATICON</span><br />
                    </div>
                    <br />
                </div>
            </div>
        </div>
    )
}


export default MentionsLegales