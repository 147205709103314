import { object, string, number, date, InferType, mixed, array } from 'yup';


export enum TypeRelation {
    Client = "CLIENT",
    NoClient= "NOCLIENT",
    Representant= "REPRESENTANT"
}

export const fileUploadSchema = mixed() 
  .test("fileSize", "The file is too large", (value) => {
      return value && value.size<2000000;
  })
  .test("type", "Only the following formats are accepted: .jpeg, .jpg, .tiff, .png, .bmp, .pdf", (value) => {
    return value && testFormat(value.type);  
  })
;

export type FileUpload = InferType<typeof fileUploadSchema>;


export const relationSPBSchema = object({
  numAdhesion: string(),
  numSinistre: string().matches(
        /^[0-9\b]+$/, { 
            message: "Numéro de sinistre invalide",
            excludeEmptyString: true 
  }),
  nomContrat: string(),
  contractSociete: string(),
  typeRelation: mixed<TypeRelation>().oneOf(Object.values(TypeRelation)).required("Merci de selectionner la relation que vous avez avec SPB !"),
 
});

const testFormat = (type:string) => {
  return (
    type === "image/jpeg" ||
    type === "image/jpg" ||
    type === "image/tiff" ||
    type === "image/bmp" ||
    type === "image/png" ||
    type === 'application/pdf'
  )
}

export type RelationSPB = InferType<typeof relationSPBSchema>;

export enum TypeCivilite {
  Mr = "MR",
  Mme= "MME",
}

export const informationsSchema = object({
  lastname: string().required("Nom requis"),
  firstname: string().required("Prénom requis"),
  email: string().email("Email invalide").required('Email requis'),
  rue: string().required("Rue Obligatoire"),
  codePostal: string().matches(
    /^[0-9\b]+$/, { 
        message: "code Postal invalide",
        excludeEmptyString: true 
  }).required("codePostal Obligatoire"),
  numTel: string().matches(
    /^[0-9\b]+$/, { 
        message: "Numéro Numerique",
        excludeEmptyString: true 
  }).required("numéro Telephone Obligatoire"),

  ville: string().required("ville Obligatoire"),
  typeCivilite: mixed<TypeCivilite>().oneOf(Object.values(TypeCivilite)).required("Civilite est obligatoire"),
  pays: string().matches(
    /^[A-Za-z]+$/, { 
        message: "Format pays incorrect",
        excludeEmptyString: true 
  }).required("pays  Obligatoire"),
});

export type Informations = InferType<typeof informationsSchema>;

export const declarationSchema = object({
  textDecla: string().required("Le champ Reclamation est obligatoire.") //bug sur le textarea
  .test("nbCar","Le nombre de caractère est au minimun de 50 et au maximum de 1500.", (arr:any) => {
    return (arr && arr.length < 1500 && arr.length > 49);  
  }),
  createdOn: date().default(() => new Date()),
  filesUpload: array().of(fileUploadSchema).
  test("NumFiles","Vous pouvez uploader seulement 2 fichiers", (arr:any) => {
    return (arr === undefined) || (arr && arr.length < 3);  
  }),
  recaptcha:string().required("La validation du captcha est obligatoire !")
});

export type Declaration = InferType<typeof declarationSchema>;

export type Reclamation = {
  relationSPB:RelationSPB,
  informations:Informations,
  declaration:Declaration,
};
