import React from "react"
import { SpbButton } from "shared/components/spb-button/spb-button";
import { ArrowLeft } from "shared/components/spb-icons/arrow-left";
import { ArrowRight } from "shared/components/spb-icons/arrow-right";

export interface IFormulaireSubmitProps {
    inProgress?:boolean;
    previous?:()=> void;
    next?:()=>void;
}

export const FormulaireSubmit:React.FC<IFormulaireSubmitProps> = ({inProgress, next, previous}) => {



    return (
        <div>
   
        <div className="row f">
        <div className="col-6 ">
            <div className="previous boxrigth">
              <SpbButton name="button-previous" type="button" onClick={previous} className="buttonleft" >
                    <ArrowLeft width={35} className="ArrwoRight" style={{stroke:"#094D9A"}}/>&nbsp;Précédent
                </SpbButton>
            </div>
        </div>  
        <div className="col-6 ">
            <div className="next boxleft">
                <SpbButton inProgress={inProgress} name="button-next" type="submit"  onClick={next} className="buttonRight">
                Suivant&nbsp;
                <ArrowRight width={35} style={{stroke:"#fff"}} className="ArrwoRight"/>
                </SpbButton>
            </div>
        </div>
    </div>
    </div>
    )
}