import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import logger from 'redux-logger';
import { rootReducer } from './reducers';

const defaultMiddleware = process.env.NODE_ENV !== 'production' ? [logger] : [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(defaultMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});


export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
