import React from "react"
import { FormulaireSubmit } from "./formulaire-submit";
import { SpbUpload } from "shared/components/spb-upload/spb-upload";
import { Form, Formik } from "formik";
import { Reclamation, declarationSchema, fileUploadSchema } from "modules/reclamation/model/reclamation";

import { SpbRecaptcha } from "shared/components/spb-recaptcha/spb-recaptcha";
import { SpbTextArea } from "shared/components/spb-text-area/spb-text-area";
export interface IFormulaireStep3Props {
    inProgress?: boolean;
    reclamation: Reclamation;
    recaptcha: string;
    submit: (reclamation: Reclamation) => void;
    cancel: () => void;
}


export const FormulaireStep3: React.FC<IFormulaireStep3Props> = ({ inProgress, reclamation, recaptcha, submit, cancel }) => {



    return (
        <div>


            <div>
                <div className='content-wrap'>
                    <div className='container'>

                        <div className='boxLinkP'>
                            <div className='textLink'>Réclamation / Formulaire
                            </div>
                        </div>

                        <div className='textTitre'>
                            Votre réclamation
                        </div>

                        <Formik
                            initialValues={reclamation.declaration}
                            validationSchema={declarationSchema}
                            onSubmit={(values) => {
                                // console.log("submit", values)
                                submit({ ...reclamation, ...{ declaration: { ...values } } });
                            }}
                        >
                            {({ values, errors, touched,submitCount, handleChange, setFieldValue, setFieldTouched, ...props }) => {
                               // console.log(values, errors,submitCount);
                                return (

                                    <div>

                                        <Form>

                                            <div className='text1'>
                                                Merci de nous communiquer toutes les informations qui nous aideront à
                                                répondre au mieux à votre réclamation : *
                                            </div>
                                            <SpbTextArea
                                                name="textDecla"
                                                row={"10"}
                                                placeholder="Ecrivez votre message ici..."
                                                initialValue={values.textDecla}
                                                handleChange={handleChange}
                                                touched={touched.textDecla}
                                                error={errors.textDecla} />

                                            <div className='boxUpload'>
                                                <div className='textTitre'>
                                                    Pièces jointes
                                                </div>
                                            </div>
                                            <div className='text1'>
                                                Vous pouvez ajouter des fichiers numérisés ou des visuels à votre message pour nous aider à traiter votre demande au plus vite.
                                                <br />Merci de ne pas nous communiquer votrenuméro de carte bancaire.
                                            </div>

                                            <div className='row'>
                                                <div className="col-12">
                                                    <div className="card text-center boxUplod ">

                                                        <div className="card-body ">
                                                            
                                                            <SpbUpload name="UploadFiles" files={values.filesUpload} onFilesAdded={async (files: FileList) => {
                                                                let filesUpload: any[] = [];
                                                                if (values.filesUpload) filesUpload = [...values.filesUpload];
                                                                for (var i = 0; i < files.length; i++) {
                                                                    let file = files[i];
                                                                    try {
                                                                        await fileUploadSchema.validate(file)
                                                                        filesUpload.push(file);
                                                                    } catch (err: any) {
                                                                        console.error(err.message)
                                                                        alert(`Impossible d'uploader ${file.name} : ${err.message}`);
                                                                    }
                                                                }

                                                                setFieldValue("filesUpload", filesUpload);

                                                            }}

                                                                deleteFile={async (file: File) => {
                                                                    let filesUpload: any[] = [...values.filesUpload!];
                                                                    filesUpload = filesUpload.filter(fileUploaded => fileUploaded.name !== file.name);
                                                                    setFieldValue("filesUpload", filesUpload);
                                                                }}
                                                            />
                                                            {errors.filesUpload && typeof errors.filesUpload === 'string' && <span className='non-valid'>{errors.filesUpload}</span>}
                                                        </div>

                                                        <div className='textboxUplod'>Ajouter ou déposer un fichier</div>
                                                        <div className='textboxUplod2'>
                                                            Taille maximale 2Mo - 2 pièces jointes au maximum <br />
                                                            Formats acceptés : jpg, tif, pdf, gif et bmp.<br />
                                                            ATTENTION : des fichiers ne peuvent pas être attachés depuis un iPad ou un iPhone.
                                                        </div>
                                                   

                                                    </div>

                                                </div>

                                            </div>

                                            <div className='row' style={{ height: 60 }}>
                                                <div className="col-12">
                                                    {recaptcha && <SpbRecaptcha
                                                        recaptcha={recaptcha}
                                                        handleChange={(token: string) => {
                                                            setFieldValue("recaptcha", token);
                                                        }}
                                                        touched={submitCount>0}
                                                        error={errors.recaptcha}
                                                    />
                                                    }
                                                </div>
                                            </div>

                                            <FormulaireSubmit inProgress={inProgress} previous={() => cancel()} />


                                        </Form>
                                    </div>
                                )
                            }}
                        </Formik>

                        <div className='textFooter'>
                            <div className='textFooter1'>* Champs obligatoires</div>
                        </div>

                    </div>
                </div>
            </div>




        </div>

    )
}

