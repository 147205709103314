import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './spb-recaptcha.css';

export interface ITextAreaProps {
  recaptcha:string;
  error?: string;
  touched?: boolean;
  handleChange?: (token:string) => void;
}


export const SpbRecaptcha: React.FC<ITextAreaProps> = ({ recaptcha, handleChange, touched, error }: any) => {

  const [state, setState] = useState({
    touched: false
   });

   useEffect(()=> {
    if (touched !== undefined) {
      setState({
        touched
      })
    }
   }, [touched])

  const onChange = (token:string|null) => {

    if (token) handleChange(token);
    
    setState({
      touched:true
    })
    
  }

  return (
    <div className="form-group">
        <div className="recaptcha">
        <ReCAPTCHA 
            data-testid="spb-captcha"
            sitekey={recaptcha}
            onChange={onChange} 
        >
            
        </ReCAPTCHA>
            
        {state.touched && error && <span data-testid="recaptcha-nonvalid" className='non-valid'>{error}</span>}
        </div>
    </div>

  );


}