import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config } from '../model/config';

// Define the initial state using that type
export const initialState = {
  recaptchaPublicKey:'' ,
  companies:[]
} as Config

export type ConfigState = typeof initialState;

export const configSlice = createSlice({
  name: 'config',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConfig: (state, action: PayloadAction<Config>)  => {
        return {...state, ...action.payload}
    }
  }
  
})

export const configActions = configSlice.actions

export default configSlice.reducer