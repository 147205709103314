import { RootState } from "config/reducers";
import { createSelector } from '@reduxjs/toolkit';

export const getError = createSelector(
    (state: RootState) => state.notification,
    notificationReducer => {

    let error = notificationReducer.error;
    if (error) {
        return `Erreur${error.code && " ("+error.code+")"} : ${error.message}`
    }

    return undefined;

    }
)

export const getMessage = createSelector(
    (state: RootState) => state.notification,
    notificationReducer=> {

    return notificationReducer.message;
    }
)