import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export interface IScrollTopProps {
  children?: React.ReactNode;
}

export const  ScrollTop:React.FC<IScrollTopProps> = ({ children }) => {
  const loc = useLocation();
  const navigationType = useNavigationType();
  useEffect(() => {
    if (navigationType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [loc]);

  return (<>{children}</>);
}