import React from "react"
import { Spinner } from "react-bootstrap";
import "./spb-spinner.css";

export interface ISpinnerProps {
}

export const SpbSpinner:React.FC<ISpinnerProps> = () => {
    
    return (
        
        <Spinner data-testid="spb-spinner" className="spb-spinner" as="span" animation="border" role="status"  size="sm">
        <span className="visually-hidden">Loading...</span>
        </Spinner>
        
    )
}