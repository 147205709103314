import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { appHistory } from "app-history";
import { RootState } from "config/reducers";
import { afficheError, afficheMessage } from "modules/notification/service/notification.service";
import { reclamationApi } from 'modules/reclamation/api/reclamation.api';
import { Reclamation } from "../model/reclamation";
import { reclamationActions } from "../store/reclamation.slice";


export const previousReclamationStep = ():ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    const step = getState().reclamation.step;
    if(step ==0) {
    appHistory.push('/');
}
    dispatch(reclamationActions.previousStep());
    

    

};

export const firstReclamationStep = ():ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    dispatch(reclamationActions.firstStep());

};


export const majReclamation = (reclamation:Reclamation):ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    dispatch(reclamationActions.updateReclamation({reclamation}));

    const step = getState().reclamation.step;

    if (step < 2) {
        dispatch(reclamationActions.nextStep());
    } else {

        try {
            //save
            dispatch(reclamationActions.progressing(true));
            //appel
            const reclamation = getState().reclamation.reclamation;

            const resp = await reclamationApi.postReclamation(reclamation);

            dispatch(reclamationActions.progressing(false));
            //
            dispatch(afficheMessage("Votre demande a été envoyée"));
            //siok > redirection home
            appHistory.push('/');

        } catch (err) {
            dispatch(reclamationActions.progressing(false));
            dispatch(afficheError(err));
        }

    }

};

