import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "config/reducers";
import { configApi } from "../api/config.api";
import { configActions } from "../store/config.slice";


export const getConfig = ():ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    const config = await configApi.getConfig();
    
    dispatch(configActions.updateConfig(config.data));

};
