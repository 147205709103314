
import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { ScrollTop } from 'shared/util/scroll-top';
import CharteConfidentialite from './charte-confidentialite/charte-confidentialite';
import ConditionsGenerales from './conditions-generales/conditions-generales';
import { Formulaire } from './formulaire/formulaire';
import Home from './home/home';
import MentionsLegales from './mentions-legales/mentions-legales';

export default function Router() {
  return (
    <ScrollTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/formulaire" element={<Formulaire />} />
        <Route path="/charte-confidentialite" element={<CharteConfidentialite />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/conditions-generales" element={<ConditionsGenerales />} />
        <Route path="*" element={<Navigate to="/" replace />}/>
      </Routes>
    </ScrollTop>
  )
}