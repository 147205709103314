import axios from 'axios';
import axiosRetry from 'axios-retry';

export const retryCondition = (error:any) => {

    if (error.response) {
        // la requête a été faite et le code de réponse du serveur n’est pas dans
        // la plage 2xx > retry if 401 or 403
        return (error.response.status === 401 || error.response.status === 403);
        
      } else if (error.request) {
        // la requête a été faite mais aucune réponse n’a été reçue
        // `error.request` est une instance de XMLHttpRequest dans le navigateur
        // et une instance de http.ClientRequest avec node.js
        console.debug("error.request", error.request);
        return true; //erreur reseau > retry
      } else {
        // quelque chose s’est passé lors de la construction de la requête et cela
        // a provoqué une erreur
        return false; // pas de retry
      }
};

export const retryConfiguration = { retries: 2, retryDelay: axiosRetry.exponentialDelay, retryCondition };

axiosRetry(axios, retryConfiguration);