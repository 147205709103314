import React from 'react'
import './spb-button-select.css'
import checked from '../../../assets/checked.png';



export const SpbButtonSelect =({name,avatar ,avatarChek, onClick, selected}:any)=>{
return (
    <div className='spb-button-select box'>
        <button data-testid={name} onClick={()=> onClick(name)}  type="button"
        className={`buttonIcon ${selected ? "change-color" : ""}`}>
        {selected ?
            <img src={avatarChek}  className='iconButtonleft'/>
        :
            <img src={avatar}  className='iconButtonleft' />
        }
        {name}
        <img src={checked} className={`icon ${selected ? "iconVisible" : ""}`}/> 
        </button>
    
    </div>
)
}