import { combineReducers } from "@reduxjs/toolkit";

import reclamation from "modules/reclamation/store/reclamation.slice";
import config from "modules/config/store/config.slice";
import notification from "modules/notification/store/notification.slice";

export const rootReducer = combineReducers({
  reclamation,
  config,
  notification
})

export type RootState = ReturnType<typeof rootReducer>
