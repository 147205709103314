import React, { useState } from "react";
import { SpbDropzone } from "../spb-dropzone/spb-dropzone";
import './spb-upload.css';

export interface ISpbUploadProps {
    name: string;
    files?: any[];
    onFilesAdded?: any;
    deleteFile?: any;
    disabled?: boolean;
}
  
export const SpbUpload:React.FC<ISpbUploadProps>=({name,files, disabled, ...props})=>{
  
    return (
        <div data-testid={"spb-upload"} className="upload">
           
            <div className="content">
                <div  className="container" >
                    <SpbDropzone  
                    name={name} 
                    onFilesAdded={props.onFilesAdded}
                    />
                </div>
                <div className="files">
                    {files && files.map((file:any) => {
                        return (
                            <div key={file.name} className="file-row">
                                <span className="filename">{file.name}</span>
                                <div className="actions" >
                                        <button data-testid={`${file.name}-delete`} type="button" onClick={() => props.deleteFile(file)}>
                                        X
                                        </button>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
           
        </div>
    )

}