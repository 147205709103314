import './root.css';

import React, { useEffect, useState } from 'react';

import Router from 'pages/router';
import Header from 'shared/components/header/header';
import Footer from 'shared/components/footer/footer';
import { useAppDispatch } from 'config/store';
import { getConfig } from 'modules/config/service/config.service';
import { SpbToast, TypeToast } from 'shared/components/spb-toast/spb-toast';
import { useSelector } from 'react-redux';
import { getError, getMessage } from 'modules/notification/store/notification.selectors';
import { getRecaptchaPublicKey } from 'modules/config/store/config.selectors';
import { SpbSpinner } from 'shared/components/spb-spinner/spb-spinner';

export const Root = () => {

  const dispatch = useAppDispatch();

  const [state, setState] = useState<{msg:string|undefined,title:string, type:TypeToast}>({
    msg:undefined,
    title:"MESSAGE",
    type:TypeToast.ERROR
  });

  const error = useSelector(getError);
  const msg = useSelector(getMessage);
  const getRecaptcha = useSelector(getRecaptchaPublicKey);
  
  useEffect(()=> {
    dispatch(getConfig());
  },[]);

  useEffect(()=> {
    setState({
      msg:error,
      title:"ERROR",
      type:TypeToast.ERROR
    })
  },[error]);

  useEffect(()=> {
    setState({
      msg,
      title:"INFORMATION",
      type:TypeToast.INFORMATION
    })
  },[msg]);

  return (
    <div className="page-container">
      <SpbToast title={state.title} message={state.msg} type={state.type}/>
      <Header />
      {getRecaptcha ?
        <div className="content-wrap" data-testid="app-router">
          <Router/>
        </div>
        : 
        <div className="content-wrap-center" data-testid="app-spinner">
          <SpbSpinner/>
        </div>
      }
      <Footer/> 
    </div>
  );
}

export default Root;
