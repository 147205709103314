import React from "react"
import { FormulaireSubmit } from "./formulaire-submit";
import { Link } from "react-router-dom"
import { Form, Formik } from "formik";
import { TextInput } from "shared/components/text-input/text-input";
import { SpbButtonSelect } from "shared/components/spb-button-select/spb-button-select";
import { Reclamation, informationsSchema, TypeCivilite } from "modules/reclamation/model/reclamation";
import Men from '../../../../assets/avatar.png';
import Menchek from '../../../../assets/avatarChecked.png';
import Woman from '../../../../assets/woman.png';
import Womanchek from '../../../../assets/womanChecked.png';


export interface IFormulaireStep2Props {
  reclamation: Reclamation;
  submit: (reclamation: Reclamation) => void;
  cancel: () => void;
}


export const FormulaireStep2: React.FC<IFormulaireStep2Props> = ({ reclamation, submit, cancel }) => {

  return (
    <div>
      <div className='content-wrap'>
        <div className='container'>

          <div className='boxLinkP'>
            <div className='textLink'>Réclamation / Formulaire
            </div>
          </div>
          <div className='textTitre'>
            Vos informations
          </div>
          <div className='text1'>
            Les données personnelles recueillies ci-après ne serviront qu'à vous recontacter dans le
            cadre de votre réclamation. <br />
            Plus d’informations dans la <Link to="/charte-confidentialite" className='linkText1'>charte de confidentialité.</Link>
          </div>
          <Formik
            initialValues={reclamation.informations}
            validationSchema={informationsSchema}
            onSubmit={(values) => {
             // console.log("submit", values)
              submit({ ...reclamation, ...{ informations: { ...values } } });
            }}
          >
            {({ values, errors,touched, handleChange, setFieldValue }) => {
              //console.log(values, errors);
              return (
                <div>
                  <Form >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                        <div className="col">
                          <div className='textPargraphI'>Identité</div>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-8">
                            <div className='text3'>Civilité*</div>
                          </div>
                        </div>

                        <div className="row boxbottom">

                          <div className="col-5">
                            <SpbButtonSelect
                              name="Madame"  avatar={Woman} avatarChek={Womanchek}
                              selected={values.typeCivilite === TypeCivilite.Mme}
                              onClick={() => setFieldValue("typeCivilite", TypeCivilite.Mme)}
                            />
                          </div>
                          <div className="col-5">
                            <SpbButtonSelect
                              name="Monsieur" avatar={Men} avatarChek={Menchek}
                              selected={values.typeCivilite === TypeCivilite.Mr}
                              onClick={() => setFieldValue("typeCivilite", TypeCivilite.Mr)}
                            />
                          </div>
                          
                          {errors.typeCivilite && <span className='non-valid'>{errors.typeCivilite}</span>}
                        </div>
                        

                        <div className="row top-buffer" >
                          <div className="col-8">
                            <TextInput
                              label="Nom* "
                              autoComplete="off"
                              type="text"
                              name="lastname"
                              placeholder="ex : Dupont"
                              initialValue={values.lastname}
                              touched={touched.lastname}
                              handleChange={handleChange}
                              error={errors.lastname} />
                          </div>
                        </div>
                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Prénom* "
                              autoComplete="off"
                              type="text"
                              name="firstname"
                              placeholder="ex : Corinne"
                              initialValue={values.firstname}
                              touched={touched.firstname}
                              handleChange={handleChange}
                              error={errors.firstname} />
                          </div>
                        </div>
                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="E-mail* "
                              autoComplete="off"
                              type="text"
                              name="email"
                              placeholder="ex : nom.prenom@email.fr"
                              initialValue={values.email}
                              touched={touched.email}
                              handleChange={handleChange}
                              error={errors.email} />
                          </div>
                        </div>


                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Numéro de téléphone * "
                              autoComplete="off"
                              type="text"
                              name="numTel"
                              placeholder="ex: 0612345678"
                              initialValue={values.numTel}
                              touched={touched.numTel}
                              handleChange={handleChange}
                              error={errors.numTel} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="row">
                          <div className="col">
                        <div className='textPargraphI'>Adresse</div>

</div></div>
                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Rue*"
                              autoComplete="off"
                              type="text"
                              name="rue"
                              placeholder="ex : 71, Quai Colbert"
                              initialValue={values.rue}
                              touched={touched.rue}
                              handleChange={handleChange}
                              error={errors.rue} />
                          </div>
                        </div>
                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Ville* "
                              autoComplete="off"
                              type="text"
                              name="ville"
                              placeholder="ex : Le Havre"
                              initialValue={values.ville}
                              touched={touched.ville}
                              handleChange={handleChange}
                              error={errors.ville} />
                          </div>
                        </div>

                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Code Postal* "
                              autoComplete="off"
                              type="text"
                              name="codePostal"
                              placeholder="ex : 76095"
                              initialValue={values.codePostal}
                              touched={touched.codePostal}
                              handleChange={handleChange}
                              error={errors.codePostal} />
                          </div>

                        </div>
                        <div className="row top-buffer">
                          <div className="col-8">
                            <TextInput
                              label="Pays*"
                              autoComplete="off"
                              type="text"
                              name="pays"
                              placeholder="ex : France"
                              initialValue={values.pays}
                              touched={touched.pays}
                              handleChange={handleChange}
                              error={errors.pays} />
                          </div>

                        </div>

                      </div>
                    </div>

                    <FormulaireSubmit previous={() => cancel()} />


                  </Form>
                </div>
              )
            }}
          </Formik>
          <div className='textFooter'>
            <div className='textFooter1'>* Champs obligatoires</div>
          </div>
        </div>
      </div>
    </div>



  )
}