import React, { useEffect, useState } from "react"
import { Toast, ToastContainer } from "react-bootstrap";
import './spb-toast.css';

export enum TypeToast {
  ERROR="danger",
  INFORMATION="success"
}
export interface IToastProps {
    title:string;
    message:string | undefined;
    type?:TypeToast;
}

export const SpbToast:React.FC<IToastProps> = ({title, message,type = TypeToast.ERROR}) => {
    
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    useEffect(()=> {
        if (message) {
            setShow(true)
        } else {
            setShow(false)
        }

    },[message])

    return (
    <ToastContainer className="p-3" position="top-end" >
        <Toast show={show} onClose={toggleShow} bg={type} delay={2000}>
          <Toast.Header >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto ">{title}</strong>
          </Toast.Header>
          <Toast.Body data-testid="toast-body" className="boxBody">{message}</Toast.Body>
        </Toast>
    </ToastContainer>
    )
}