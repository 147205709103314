import React from "react"
import { SpbSpinner } from "../spb-spinner/spb-spinner";


export interface IButtonProps {
    name:string;
    inProgress?:boolean;
    children?: React.ReactNode;
    className?: string;
    type?: "button" | "submit" | "reset" | undefined;
    onClick?:()=> void;
}

export const SpbButton:React.FC<IButtonProps> = ({name, inProgress, children, className,type, onClick}) => {
    
    return (
        
        <button disabled={inProgress} data-testid={name} type={type} style={{fill:''}} className={className} onClick={onClick}>
            {!!inProgress ?
                <SpbSpinner />
            :
                children
            }
        </button>
       
    )
}