import React from 'react';
import './footer.css';
import { Link } from "react-router-dom"
export const Footer = () => {
  const logo: string = require("../../../assets/logo.svg").default;
  return (
    <div data-testid={"footer"} className="main-footer">
      <div className="container">

        <div className="row">
          <img src={logo} alt="logo Spb" className="image" />
        </div>


        <div className="row">
          <Link to="/mentions-legales" className='text'>Mentions légales</Link>
        </div>

        <div className="row">
          <Link to="/conditions-generales" className='text'>Conditions Générales d'Utilisation</Link>
        </div>

        <div className="row">
          <div className="boxF">
            <Link to="/charte-confidentialite" className='text'>Charte de confidentialité</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

