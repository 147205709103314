import React from "react"
import classNames from "classnames";

export interface IArrowRightProps {
    className?: string;
    style?: any;
    width?: number;

}
export const ArrowRight: React.FC<IArrowRightProps> = ({ width, style, className }) => {

    return (
        <svg data-testid={"spb-icon-arrow-right"} style={{ fill: "#000000", ...style }} width={width ?? 24} viewBox="0 0 24 24" id="right-arrow" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className={classNames("icon flat-line", className)}>
            <g strokeWidth="0"></g>
            <g><polyline id="primary" points="18 15 21 12 18 9" style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2, ...style }}>
            </polyline></g>
            <line x1="3" y1="12" x2="21" y2="12" style={{ fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2, ...style }}></line>
        </svg>
    )
}