import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "config/reducers";
import { notificationsActions } from "../store/notification.slice";

let timeout:any;

export const afficheError = (error:any):ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    dispatch(notificationsActions.setError(error));

    if (error) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(notificationsActions.reset());
        }, 3000);
    }

};

export const afficheMessage = (msg?:string):ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {

    if (msg) {
        dispatch(notificationsActions.setMessage(msg));
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(notificationsActions.reset());
        }, 3000);
    }

};


