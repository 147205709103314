import { Reclamation } from "modules/reclamation/model/reclamation";
import { TypeStep } from "modules/reclamation/store/reclamation.slice";
import React from "react";
import { FormulaireStep1 } from "./formulaire-step-1";
import { FormulaireStep2 } from "./formulaire-step-2";
import { FormulaireStep3 } from "./formulaire-step-3";

export interface IFormulaireProps {
    step: TypeStep;
    inProgress?:boolean;
    reclamation:  Reclamation;
    recaptcha: string;
    submit:(reclamation:Reclamation)=> void;
    cancel:()=> void;
}

export const FormulaireStep:React.FC<IFormulaireProps> = ({step,inProgress, reclamation,recaptcha, submit, cancel}) => {
    

    return (
        <div className="formulaire">
         
            {step===TypeStep.Informations?
                <FormulaireStep2  reclamation={reclamation} cancel={cancel} submit={submit}/>
            :step===TypeStep.Reclamation?
                <FormulaireStep3 inProgress={inProgress}  reclamation={reclamation} recaptcha={recaptcha} cancel={cancel} submit={submit}/>
            ://default
                <FormulaireStep1 reclamation={reclamation}  cancel={cancel} submit={submit}/>
            }
        </div>
    )
}