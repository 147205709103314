import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppError } from '../model/app-error';


// Define the initial state using that type
export const initialState = {
  error: undefined as AppError | undefined,
  message: undefined as string | undefined,
}

export type NotificationState = typeof initialState;

export const notificationSlice = createSlice({
  name: 'notification',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state)  => {
      state.error = undefined;
      state.message = undefined;
    },
    setMessage:(state,action: PayloadAction<string>)  => {
        state.message = action.payload;
    },
    setError: (state,action: PayloadAction<any>)  => {

        let _error = action.payload;
        let appError = {} as AppError;
        if (_error && _error.code) {
            appError.code = _error.code
        }

        if (_error && _error.message) {
            appError.message = _error.message
        }

        if (_error && _error.name) {
            appError.name = _error.name
        }

        if (_error && _error.response) { // response Error Axios
            if (_error.response.data) {
                if (_error.response.data.code) {
                    appError.code = _error.response.data.code
                }
                if (_error.response.data.message) {
                    appError.message = _error.response.data.message
                }
                if (_error.response.data.name) {
                    appError.name = _error.response.data.name
                }
            }
            if (_error.response.status) {
                appError.status = _error.response.status
            }
        }


        if (!existError(appError)) state.error = undefined
        else state.error = appError;
       
    }
  }
  
})

const existError = (error:AppError) => {
    return Object.keys(error).length > 0
}

export const notificationsActions = notificationSlice.actions

export default notificationSlice.reducer