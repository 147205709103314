import React from 'react';
import { Link } from "react-router-dom";

export const CharteConfidentialite = () => {

      return (
            <div>
                  <div className='content-wrap'>
                        <div className='container'>

                              <div className='boxLink'>
                                    <Link to="/" className='textLink'>Accueil</Link>
                                    <span className='textLink'> / Charte de confidentialité</span><br />
                              </div>
                              <div className='textParagraph'>
                                    La présente charte de confidentialité a pour objet d’informer la personne physique ayant accès au Site (l’ « Utilisateur ») sur ses droits ainsi
                                    que sur les mesures<br /> prises par SPB afin de veiller au respect de ses données à caractère personnel, en application du Règlement Général sur
                                    la Protection des Données (RGPD) et de <br /> la Loi  Informatique et Libertés, lors de l’utilisation du présent site internet,
                                    ci-après dénommé le « Site ».
                              </div>
                              <div className='boxtextTitre3'>
                                    <div >
                                          Politique de traitement des données à caractère personnel
                                    </div>
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>I) RESPONSABLE DE TRAITEMENT</span><br />
                              </div>
                              <div className='textParagraph'>
                                    La présente politique de traitement des données à caractère personnel porte sur les données traitées par SPB
                                    en qualité de responsable de traitement : SPB, <br />Société par Actions Simplifiée au capital de 1 000 000 euros, immatriculée au Registre du
                                    Commerce et des Sociétés (RCS) du Havre sous le numéro 305 109 779<br /> et à l’ORIAS (www.orias.fr) en qualité de courtier d’assurances sous
                                    le numéro 07 002 642, dont le siège est situé 71, Quai Colbert 76095 LE HAVRE Cedex.
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>II) DONNEES A CARACTERE PERSONNEL COLLECTEES</span><br />
                              </div>
                              <div className='textParagraph'>
                                    Les données à caractère personnel sont collectées directement auprès de l’Utilisateur lorsque celui-ci remplit le formulaire en ligne et
                                    enregistrées au sein du Site.<br /> Seules les données à caractère personnel pertinentes et utiles à la formulation et au traitement d’une
                                    réclamation sont collectées, il s’agit notamment : <br />• des données relatives à l’identité de l’Utilisateur (nom, prénom(s), date de naissance) ;
                                    <br /> • des données de contact (numéro de téléphone, adresse électronique et postale).
                              </div>


                              <div className='boxtextTitre1'>
                                    <div className='textTitre1'>III) BASE LEGALE DU TRAITEMENT DE DONNEES A CARACTERE PERSONNEL</div><br />
                              </div>
                              <div className='textParagraph'>
                                    La base légale du traitement des données à caractère personnel est le contrat d’assurance objet de la réclamation. La collecte des données
                                    à caractère personnel est obligatoire pour la formulation d’une Réclamation par l’Utilisateur et pour le traitement de sa demande : toutes
                                    les informations sont obligatoires sauf mention contraire. Le défaut de réponse peut entraîner l'impossibilité de traiter la demande de l’Utilisateur.
                                    Ce dernier est informé que les informations transmises peuvent faire l'objet de vérifications. Ainsi, le traitement des données à caractère personnel
                                    par SPB a pour finalités :<br /> • la réception, l’instruction et l’enregistrement de la réclamation formulée par l’Utilisateur ; <br />• la conclusion et
                                    l’exécution du contrat d’assurance ; <br />• la mise en oeuvre des obligations de vigilance dans le cadre de la lutte contre le blanchiment d'argent
                                    et le financement du terrorisme, les mesures de gel des avoirs, la lutte contre le financement du terrorisme et les sanctions financières, y compris
                                    le déclenchement d'alertes et les déclarations de soupçon, <br />• la lutte contre la fraude,<br /> • le suivi de la qualité des services via, notamment, des
                                    enquêtes de satisfaction, <br />• l’élaboration d’analyses statistiques. Des données indirectement nominatives peuvent être collectées lors de la navigation
                                    de l’Utilisateur. L’Utilisateur est invité à se reporter à la rubrique relative à la « Politique d’utilisation des cookies » ci-après, afin d’en
                                    savoir plus sur les finalités et ses droits associés.
                              </div>

                              <div className='boxtextTitre1'>
                                    <div className='textTitre1'>IV) DUREE DE CONSERVATION </div><br />
                              </div>
                              <div className='textParagraph'>
                                    Les données collectées sont conservées jusqu’au terme de
                                    la prescription des actions pouvant découler desdits contrats (5 ans, sauf pour les contrats portant sur les dommages corporels, auquel
                                    cas la prescription est de 10 ans).
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>V) DESTINATAIRE DES DONNEES A CARACTERE PERSONNEL</span><br />
                              </div>
                              <div className='textParagraph'>
                                    Les données collectées sont destinées exclusivement aux assureurs,
                                    partenaires et sous-traitants/mandataires intervenant dans la conclusion et/ou l’exécution des contrats d’assurance des éventuels souscripteurs ou
                                    adhérents. En dehors des destinataires ci-dessus énumérés et sous réserve d’y être contraint par une autorité judiciaire ou par la loi, ou pour
                                    protéger les droits et intérêts de SPB, les données ne sont pas communiquées, partagées, mises à disposition ou vendues aux tiers sans l’accord
                                    exprès et préalable des personnes concernées.

                              </div>


                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>VI) TRANSFERT DES DONNEES HORS DE L’UNION EUROPEENN</span><br />
                              </div>
                              <div className='textParagraph'>
                                    Si un transfert hors de l’Union européenne
                                    des données personnelles collectées et traitées devait être réalisé, des garanties seraient prises pour encadrer juridiquement  <br />ce transfert
                                    et assurer un bon niveau de protection des données de l’Utilisateur.
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>VII) MESURE DE SECURITE DES DONNEES A CARACTERE PERSONNEL SPB</span><br />
                              </div>
                              <div className='textParagraph'>
                                    prend toutes les précautions utiles et met en oeuvre toutes les mesures techniques ou organisationnelles appropriées qui permettront d’assurer la sécurité,
                                    la confidentialité, l’intégrité des données personnelles, ainsi que la traçabilité des actions effectuées. A cet effet, SPB prend toutes les
                                    précautions nécessaires à la sécurité et à la confidentialité des données à caractère personnel afin qu’elles ne soient pas endommagées, effacées
                                    ou que des tiers non autorisés y aient accès.

                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>VIII) DROIT DES UTILISATEURS</span><br />
                              </div>
                              <div className='textParagraph'>
                                    L’Utilisateur dispose d’un droit d’accès, d’opposition pour motif
                                    légitime, de rectification et de portabilité de ses données à caractère personnel collectées et/ou traitées par SPB ainsi que le droit de définir
                                    des directives relatives au sort de ses données après son décès. L’Utilisateur dispose également d’un droit de suppression sur les données
                                    personnelles le concernant qui sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l’utilisation, la communication ou la
                                    conservation est interdite. Ces droits s’exercent selon les modalités définies par les dispositions légales et réglementaires en vigueur
                                    relatives au traitement des données personnelles, en contactant SPB, par tout moyen permettant de justifier de son identité, tel qu’un écrit
                                    accompagné d’un titre d’identité daté et signé, aux coordonnées ci-après : <br />• SPB – Délégué à la Protection des Données – 71, Quai Colbert – 76600
                                    Le Havre <br />• spb-dpo@spb.eu L’Utilisateur est informé qu’il dispose du droit d’introduire une réclamation auprès de la Commission Nationale de
                                    l’Informatique et des Libertés (CNIL).
                              </div>

                              <div className='boxtextTitre3'>
                                    <span >
                                          Politique d’utilisation des cookies
                                    </span>
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>I) QU’EST-CE QU’UN COOKIE ?</span><br />
                              </div>
                              <div className='textParagraph'>
                                    Un « cookie » (ou traceur) est une suite d'informations, généralement de petite taille et identifié par un nom,
                                    qui peut être transmis au navigateur <br /> de l’Utilisateur par un site web sur lequel il s’est connecté. Le navigateur web le conservera pendant
                                    une certaine durée, et le renverra au serveur web chaque <br />fois que l’Utilisateur s’y reconnectera. Les cookies ont de multiples usages :
                                    ils peuvent servir à mémoriser un identifiant client auprès d'un site marchand,<br /> le contenu courant d’un panier d'achat, un identifiant
                                    permettant de tracer la navigation pour des finalités statistiques ou publicitaires, etc.
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>II) QUELS TYPES DE COOKIES SONT UTILISES PAR LE SITE ET A QUELLES FINALITES ?</span><br />
                              </div>

                              <div className='textParagraph'>
                                    Lors de la visite du Site, celui-ci stocke l’adresser IP de l’Utilisateur
                                    et utilise des cookies pour faciliter sa visite sur le site Web, améliorer son expérience  et compiler<br /> des statistiques.<br /> Les cookies utilisés
                                    sur le Site sont les suivants : <br /> • Cookies strictement nécessaires :<br /> Ces cookies ne stockent aucune information permettant d’identifier personnellement
                                    l’Utilisateur. Ils sont cependant essentiels pour permettre la navigation <br />sur le Site. Ils ne sont généralement définis qu’en réponse à des
                                    actions effectuées par l’Utilisateur, qui reviennent à une demande de service, comme la configuration<br /> de ses préférences de confidentialité,
                                    la connexion ou le remplissage de formulaires.<br /> Les cookies d’authentification et de sécurité sont par exemple utilisés pour identifier et
                                    reconnaître l’Utilisateur enregistré et pour lui permettre d’avoir accès au contenu ou aux fonctionnalités demandées.<br /> L’Utilisateur peut
                                    paramétrer son navigateur pour bloquer ou l’alerter sur la présence de ces cookies, mais sans eux, les services demandés par l’Utilisateur ne peuvent
                                    pas être fournis.
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>III) LA DUREE DE CONSERVATION D’UN COOKIE</span><br />
                              </div>
                              <div className='textParagraph'>
                                    Les cookies ont une durée de vie de 13 mois à compter du jour où l’Utilisateur a donné son
                                    consentement à leur utilisation. L’Utilisateur dispose de la possibilité de les retirer de son terminal et ce, à tout moment, selon les modalités
                                    décrites à l’article IV ci-dessous.
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>IV) LE PARAMETRAGE DES COOKIES </span><br />
                              </div>
                              <div className='textParagraph'>
                                    L’Utilisateur peut désactiver l’utilisation des cookies à tout moment en visitant
                                    la rubrique « Gestion des cookies » située en bas de page du Site ou se rendant dans son navigateur internet :<br />
                                    <div className='boxParagraph5'>
                                          • Dans Internet Explorer :
                                          Dans le « Menu », cliquer sur « Options internet », puis sur l’onglet « Confidentialité » et paramétrer les cookies en cliquant sur <br /> « Sites » ou
                                          « Avancé ».<br /> </div>  <div className='boxParagraph5'>• Dans Firefox : <br /> Dans le « Menu », cliquer sur « Options », choisir « Vie privée » et cliquer sur « Affichez les cookies »,
                                                Paramétrer le menu « Règles de conservation » <br />sur « Utiliser les paramètres personnalisés pour l'historique ». Enfin, décocher la case
                                          "Accepter les cookies tiers". </div><div className='boxParagraph5'>• Dans Google Chrome : <br />Dans le « Menu », cliquer sur « Paramètres », puis sur l’onglet « Confidentialité et sécurité »
                                                et accéder à la section « Cookies et autres données des sites », <br /> choisir ensuite le type de blocage des cookies souhaité.
                                    </div><div className='boxParagraph5'>• Dans Safari : <br />Dans le « Menu », cliquer sur « Préférences », cliquer ensuite sur « Confidentialité » choisir ensuite le type de blocage des cookies
                                          souhaité</div>.<br /> A noter : la modification des préférences de cookies ou leur suppression peut avoir un impact sur la navigation sur d’autres sites web.

                              </div>

                              <div className='boxtextTitre6'>
                                    <span className='textTitre1'>V) EN SAVOIR PLUS SUR LES COOKIES </span><br />
                              </div>
                              <div className='boxtextParagraphlink'>
                                    <a target="_blank" href="https://www.cnil.fr/fr/cookies-et-autres-traceurs"
                                          className='textParagraphlink'>
                                          https://www.cnil.fr/fr/cookies-et-autres-traceurs
                                    </a>
                              </div>
                        </div>
                  </div>
            </div>
      );
}


export default CharteConfidentialite