import axios, { AxiosResponse } from 'axios';
import { Config } from '../model/config';

export interface IConfigApi {
  getConfig(): Promise<AxiosResponse<Config>>;
}

class ConfigApi implements IConfigApi {

  async getConfig(): Promise<AxiosResponse<Config>> {
    return await axios.get('/api/config');
  }

}

const configApi = new ConfigApi();

export { configApi };