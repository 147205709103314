import React from 'react';
import './home.css';
import { useNavigate } from "react-router-dom";

export const Home = () => {
  
  const navigate = useNavigate();

  return (
    <div>
      <div className='content-wrap'>
        <div className='container'>
          <div className='titre1'>Comment formuler une réclamation ?</div><br />
          <div className='text1'>
            En cas de difficulté ou d'insatisfaction relative à la conclusion ou à l'exécution de la
            gestion d'un contrat d'assurance, toute personne peut adresser sa réclamation au
            Département Réclamations de SPB.
            Une réponse rapide et personnalisée de nos équipes vous sera apportée.
          </div>
          <div className="row">
            <div className="col-md-6  align-items-stretch col-stretch">

              <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded card-stretch ">

                <div className="card-title  ">
                  <span className='textBody'>Par Internet</span>
                </div>
                <div className='card-text  col  d-flex justify-content-center align-items-center'>
                  <button className='button1' onClick={() => navigate("/formulaire")}>Formulaire de réclamation</button>
                </div>

              </div>

            </div>
            <div className="col-md-6  align-items-stretch col-stretch">
              <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded card-stretch">

                <div className="card-title ">
                  <span className='textBody'>Par courrier</span>
                </div>
                <div className='card-text'>
                  <div className='textCard'> SPB<br />
                    Département Réclamations <br />
                    CS 90000<br />
                    76095 Le Have Cedex
                  </div>
                </div>

              </div>

            </div>
            <div className="col-12">
              <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded">

                <div className="card-title ">
                  <div className='boxtextBody'>
                    <span className='textBody'>Nos engagements </span> <span className='textBody01'>*</span>

                  </div>
                </div>
                <div className="card-body">
                  <div className='textParagraph'>

                    <div className="row par1">
                      <div className="col">
                        <div className="well">
                          <span className='point'>•</span>

                       
                      
                     
                            Nous accusons réception de votre réclamation formulée par écrit,
                              dans un délai de 10 jours ouvrables à compter de son envoi
                              (même si la réponse à la réclamation est également apportée dans ce délai).
                              </div>
                              </div>
                        
                              </div>

                  </div>
                 

                    <div className="row par1">
                    <div className='textParagraph cardParagraphEngagement'>
                      <div className="col">
                        <div className="well">
                          <span className='point'>•</span>
                       
                   
                            Nous apportons une réponse à votre réclamation dans
                              un délai de 2 mois suivant la date d'envoi de votre réclamation.
                              </div>
                      </div>
                    </div>
                  </div>
                  <div className='textParagraph1 cardParagraphBottom'>
                    * en application des dispositions de la "Recommandation sur le traitement des réclamations" émise sous les numéro 2016-R-02 et modifiée par la nouvelle Recommandation n° 2022-R-01 par
                    l'ACPR (Autorité de <br/> Contrôle Prudentiel de Résolution) le 9 mai 2022.
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div className='titre2'>Vous n'êtes pas satisfait de la réponse du Département Réclamations de SPB ?</div><br />
          <div className='text1'>
            Vous disposez de la possibilité de vous adresser auprès de l'assureur du produit d'assurance : vous pouvez en demander les coordonnées
            en vous adressant à :<span className='textlink'> reclamations@spb.eu</span>
          </div>


          <div className="row">
            <div className="col-md-6  align-items-stretch col-stretch ">

              <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded  card-stretch">

                <div className="card-title  ">
                  <span className='textBody1'>Dans tous les cas, vous pouvez saisir la Médiation de l'assurance 2 mois après l'envoi de votre première réclamation écrite,
                    à l'adresse suivante :</span>
                </div>
                <div className='card-text'>
                  <div className='textParagraph2'>
                    <b>  Médiateur de l’assurance   </b><br />
                    TSA 50110 <br />
                    75441 Paris Cedex 09 <br />
                    Site internet : www.mediation-assurance.org
                  </div>
                  <div className='textParagraph3 cardParagraphBottomText'>
                    Les dispositions qui précédent,
                    ne vous empêchent pas d'utiliser toute autre voie d'action légale.
                  </div>
                  <div className='textParagraph3 cardParagraphBottomText'>
                    Vous disposez d'un délai d'un an à compter de votre réclamation écrite
                    pour  saisir <br /> le Médiateur de l'assurance.
                  </div>

                </div>

              </div>

            </div>
            <div className="col-md-6  align-items-stretch col-stretch">
              <div className="card text-center  shadow-sm p-3 mb-5 bg-white rounded  card-stretch">

                <div className="card-title ">
                  <div className='textBody1'>Votre assureur est une mutuelle d'assurance et vous agissez en qualité de particulier : </div>
                  <div className='textBody1'>Saisissez le médiateur du GEMA <br /> ( Groupement des Entreprises Mutuelles d'Assurance )</div>
                </div>
                <div className='card-text'>
                  <div className='textParagraph2 cardParagraphBottomText'>
                    Service Médiation du GEMA<br />
                    9 rue de Saint Petersbourg<br />
                    75008 Paris<br />
                  </div>


                  <div className='textParagraph3 cardParagraphBottomText2'>
                    Ce dispositif de médiation est défini par un <b>protocole de la médiation</b>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home
