import React, { useEffect, useState } from 'react';
import './text-input.css';

export interface ITextInputProps {
  name:string;
  type:string;
  label?: string;
  value?: any;
  initialValue?: any;
  error?:string;
  autoComplete?:string;
  placeholder?:string;
  disabled?:boolean;
  touched?:boolean;
  handleChange?:any;
  limit?:any;

  
}

export const TextInput: React.FC<ITextInputProps> = ({ value, label,name, placeholder,autoComplete, initialValue, type, disabled, touched, handleChange , error}:any)=> {
  
  const [state, setState] = useState({
    touched: false
   });

   useEffect(()=> {
    if (touched !== undefined) {
      setState({
        touched
      })
    }

   }, [touched])
   
    const onChange = (e:React.ChangeEvent<any>) => {
        handleChange(e);
        setState({
          touched:true
        })
    }

    return(
    <div className="form-group">
      {label && <label className='textlabel' htmlFor="input-field">{label}</label>}
      <input
        data-testid={name}
        type={type}
        value={value}
        defaultValue={initialValue}
        name={name}
        className="form-control input1"
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
      />
      {state.touched && error && <span className='non-valid'>{error}</span>}
    </div>
);
}