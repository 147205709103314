import React from 'react';
import { Link } from "react-router-dom"

export const ConditionsGenerales = () => {

      return (
            <div>
                  <div className='content-wrap'>
                        <div className='container'>

                              <div className='boxLink'>
                                    <Link to="/" className='textLink'>Accueil</Link>
                                    <span className='textLink'> / Conditions Générales d'Utilisation</span><br />
                              </div>

                              <div className='boxTitre'>
                                    <p className='textParagraph'>Le présent site internet ainsi que ses contenus et services,
                                          ci-après ensemble dénommés le « Site », sont publiés par :</p> <br />
                              </div>
                              <div className='boxParagraph'>
                                    SPB<br />
                                    <div> Société par Actions Simplifiée au capital de 1 000 000 euros,<br />
                                          Immatriculée au Registre du Commerce et des Sociétés (RCS) du Havre sous le n°305 109 779 et à l’ORIAS sous le numéro 07 002 642<br />
                                          (www.orias.fr) en qualité de courtier d’assurances,<br />
                                          Siège social : 71, quai Colbert - 76095 LE HAVRE Cedex.<br />
                                    </div>
                              </div>
                              <div className='textParagraph'>Les présentes conditions générales d’utilisation du Site, ci-après dénommées « CGU » définissent les conditions applicables entre<br />
                                    l’Utilisateur et SPB ainsi que les droits et obligations de chacun, relativement à l’utilisation du Site.<br />
                                    Toute utilisation du Site suppose la consultation et l’acceptation des présentes CGU<br />
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>1. DEFINITIONS</span><br />
                              </div>

                              <div className='textParagraph'>Les termes listés ci-dessous utilisés dans les présentes CGU et débutant par une majuscule s’entendront comme suit, indifféremment au singulier et au pluriel, <br />selon le contexte de leur emploi :

                                    <br /></div>
                              <div className='boxtextTitre2'>
                                    <div className='textParagraph'><span className='textParagraph2'>CGU : </span>
                                          Désigne les présentes conditions générales
                                          d’utilisation destinées à encadrer les modalités d’utilisation du Site par l’Utilisateur.</div>
                              </div>

                              <div className='boxtextTitre2'>
                                    <div className='textParagraph'><span className='textParagraph2'>Contenu : </span>

                                          Désigne l’ensemble des documents,
                                          illustrations, fiches, questionnaires, fonctionnalités et plus largement tout élément existant au sein du Site.
                                    </div>
                              </div>
                              <div className='boxtextTitre2'>
                                    <div className='textParagraph'><span className='textParagraph2'>Service :  </span>

                                          Désigne l’ensemble des services proposés et mis à la disposition des Utilisateurs au sein du Site tels que décrits dans les CGU.
                                          Les Services sont fournis <br />via le Site  soit par SPB, soit par des sociétés tierces sous leur responsabilité exclusive,
                                          ce que reconnait et accepte l’Utilisateur.
                                    </div>
                              </div>

                              <div className='boxtextTitre2'>
                                    <div className='textParagraph'><span className='textParagraph2'> Site :  </span>

                                          Désigne le site web édité par SPB et l’ensemble des Services et
                                          Contenus mis à la disposition des Utilisateurs.
                                    </div>
                              </div>

                              <div className='boxtextTitre2'>
                                    <div className='textParagraph'><span className='textParagraph2'>Utilisateur : </span>

                                          Désigne la personne physique ayant accès au Site pour
                                          formuler une réclamation
                                    </div>
                              </div>


                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>2. UTILISATION DU SITE PAR L’UTILISATEUR</span><br />
                              </div>

                              <span className='textParagraph3'>Acceptation des CGU : </span><br />
                              <div className='textParagraph'>
                                    L’accès et l’utilisation du Site par
                                    l’Utilisateur impliquent la connaissance, l’acceptation sans réserve et le respect par ce dernier des présentes CGU.
                                    <br />   Un accès aux CGU sera disponible à partir de l’icône « Conditions générales d’utilisation » du Site.

                                    <br /> SPB se réserve
                                    le droit de modifier à tout moment les termes des présentes CGU. Dans ce cas, les CGU modifiées seront portées à la

                                    <br />   connaissance de
                                    l’Utilisateur<br /> à partir de l’icône « Conditions générales d’utilisation » du Site.


                                    La connexion au Site par l’Utilisateur vaudra
                                    acceptation des CGU modifiées.
                              </div>


                              <div className='boxtextText2'>
                                    <p className='textParagraph3'>Règles d’utilisation du Site </p>
                              </div>
                              <div className='textParagraph'>
                                    L’Utilisateur s’engage à utiliser le Site de façon loyale et honnête,
                                    conformément à son objectif et à son rôle, dans le respect de la


                                    législation et de la plus stricte confidentialité.


                                    <br />SPB ne pourra être tenue pour responsable des préjudices que causerait l’Utilisateur en utilisant des informations issues de cet espace



                                    sécurisé
                                    à d’autres fins que  <br />celles  auxquelles elles sont destinées. <br />


                                    L’Utilisateur reconnaît et accepte que le respect des présentes CGU est une condition
                                    substantielle au maintien de son accès au Site.



                                    SPB pourra ainsi supprimer <br />l’accès au Site ou à certaines fonctionnalités du Site,
                                    sans préavis et sans devoir, de ce fait, à l’Utilisateur


                                    d’indemnité de quelque nature que ce soit, en cas de violation<br /> par
                                    l’Utilisateur des dispositions des présentes CGU rendant impossibl


                                    le maintien de l’accès au Site, et ce, nonobstant
                                    les dommages et intérêts qui pourraient être demandés par SPB.
                              </div>
                              <div className='boxtextTitre1'>
                                    <div className='textTitre1'>3. DESCRIPTION ET MODALITES DE MISE EN OEUVRE DES SERVICES ACCESSIBLES
                                          DEPUIS LE SITE</div>

                                    <div className='textParagraph'>
                                          Les Services du Site sont exclusivement destinés aux Utilisateurs souhaitant se renseigner sur les modalités de réclamation auprès SPB.
                                    </div>
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          4. DESCRIPTION DES FONCTIONNALITES DU SITE ET INTEROPERABILITE
                                    </span> </div>
                              <span className='textParagraph'>
                                    1. Principales caractéristiques : Site pour formuler une Réclamation par l’Utilisateur.
                              </span><br />
                              <div className='boxtextText3'>
                                    <div className='textParagraph'>
                                          2. Fonctionnalités :

                                          <br />
                                          <span className='textParagraphLigne'>Langue : </span>
                                          Site internet et instructions en français.

                                          <br /> <span className='textParagraphLigne'>   Type d'accès :  </span>

                                          Application (software) pouvant se connecter
                                          au réseau internet pour l’envoi et la réception des données.

                                          Conditions d'accès :

                                          Accès public.


                                          <br /> <span className='textParagraphLigne'>   Traçabilité des données  :  </span>
                                          Les données à caractère personnel sont destinées à l’assureur concerné ainsi qu’à SPB en leur qualité de responsables de
                                          traitement, ainsi qu’à leurs sous-traitants.

                                          <br /> <span className='textParagraphLigne'>        Connexion internet :  </span>

                                          Vitesse de téléchargement d’au moins 6 Mbps requise
                                          pour des performances optimales
                                    </div>
                              </div>

                              <div className='boxtextText3'>
                                    <div className='textParagraph'>
                                          3. Interopérabilité
                                          <br />

                                          Matériel & logiciel : Comptabilité assurée pour les navigateurs
                                          actifs du marché, dont l’éditeur n’a pas annoncé la fin du support dans


                                          les 12 prochains mois et dont la part de marché en France (web ou mobile)
                                          est d’au moins 2%.
                                    </div>
                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          5. MISE EN GARDE/CONTRAINTES TECHNIQUES
                                    </span> </div>
                              <div className='textParagraph'>
                                    L’Utilisateur est informé des contraintes et limites du réseau Internet,
                                    tout particulièrement en termes de :
                                    <br />

                                    - transmission des données et d’atteintes possibles aux données ;
                                    <br />

                                    - continuité non garantie dans l’accès au Site ou aux différents Services offerts par le Site ;
                                    <br />

                                    - contraintes techniques qui ne sont pas sous le contrôle et la responsabilité de SPB.
                                    <br />
                                    En aucun cas, SPB ne saurait être tenue responsable de ces risques et de leurs conséquences préjudiciables,
                                    quelle qu’en soit l’étendue pour l’Utilisateur.<br />


                                    Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses
                                    propres données, logiciels et matériels,


                                    de la contamination <br />par des virus ou autres formes d’attaques circulant éventuellement à travers le Site.
                                    <br />

                                    L’Utilisateur ne saurait se prévaloir d’aucune difficulté d’accès au Site pour accéder à l’information ou aux Services en fraude des présentes CGU.
                                    <br />

                                    Des liens peuvent renvoyer vers d'autres applications, sites internet ou autres réseaux sociaux ou services fournis par des sociétés tierces à SPB.


                                    La responsabilité <br /> de SPB ne saurait être engagée dans le cas où le contenu desdits autres applications,
                                    sites internet ou réseaux sociaux ou services  contreviendrait aux droits de tiers et à toutes dispositions légales ou réglementaires en vigueur. Ces autres applications,
                                    sites internet ou autres
                                    réseaux sociaux ou services sont fournis sous la responsabilité exclusive des sociétés qui les éditent, ce que reconnait et accepte expressément
                                    l’Utilisateur.<br /> L’Utilisateur est informé du défaut de fiabilité du réseau Internet, tout spécialement en termes de sécurité relative dans
                                    la transmission des données, de continuité non garantie dans l'accès au Site, de performances non garanties en termes de volume et de rapidité
                                    de transmission des données et de propagation de virus.<br /> A ce titre, SPB ne pourra être tenue pour responsable des dommages résultant de virus
                                    quelle qu'en soit la forme, de bug(s), voire de tout programme ou d'application qui serait incompatible avec l'infrastructure utilisée p
                                    ar l’Utilisateur, ni des dommages subis par l’Utilisateur par le fait d'une panne, interruption maintenance, problème technique,
                                    coupure du réseau téléphonique ou des réseaux de télécommunications, surcharge, négligence ou faute de tiers ou de l’Utilisateur
                                    ou d’une incompatibilité de la configuration de l’ordinateur de l’Utilisateur.<br /> L’Utilisateur est responsable de la mise en oeuvre
                                    au sein de son ordinateur d’une solution et des mesures de sécurité de nature à prévenir la propagation de virus. <br />Il appartient à l’Utilisateur
                                    de vérifier l’adéquation des Services et Contenus proposés via le Site, à ses besoins. L’Utilisateur est seul responsable de toute décision de
                                    quelque nature qu’elle soit, prise à partir d’une information fournie au sein des Contenus ou Services proposés via le Site.
                              </div>


                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          6. RESPONSABILITES
                                    </span> </div>

                              <div className='textParagraph'>  <span className='textParagraph2'>6.1 Responsabilité de l’Utilisateur </span><br />

                                    L’Utilisateur demeure pleinement et personnellement responsable de l’utilisation du Site dans les conditions
                                    de droit commun.<br /> Il appartient à l’Utilisateur : <br /> • d'avertir SPB de tout dysfonctionnement technique constaté et de toute anomalie découverte,
                                    notamment de tout signe d’intrusion ; <br />• d’utiliser le Site dans le respect des dispositions légales et règlementaires en vigueur ainsi que dans
                                    le respect des stipulations des présentes CGU.
                              </div>
                              <div className='boxtextParagraph4'>
                                    <span className='textParagraph2'>6.2 Responsabilité de SPB  </span>
                              </div>
                              <div className='textParagraph'>

                                    Le Site est utilisé sous les seuls responsabilités, contrôles et direction
                                    de l’Utilisateur. SPB ne pourra en aucun cas être tenue pour responsable de tous préjudices <br />directs et indirects, quels qu'ils soient,
                                    issus de l’utilisation par l’Utilisateur du Site et des Services à quelque titre et en quelques circonstances que ce soit.<br /> A ce titre,
                                    l’Utilisateur s’engage à valider l’adéquation du Site et des Services à ses besoins, et à utiliser le Site et les Services dans des conditions
                                    normales et conformément à sa destination. <br />SPB n’est en aucun cas responsable de la qualité, de l’actualité, de la complétude et de la véracité
                                    des données saisies par l’Utilisateur sous ses seuls responsabilités, contrôles et direction.<br /> EN AUCUN CAS, SPB N’EFFECTUE DE VERIFICATION OU DE
                                    CONTROLE DE COHERENCE OU DE PERTINENCE DES DONNEES SAISIES PAR L’UTILISATEUR <br />AU SEIN DU SITE LORS DE SON UTILISATION DES SERVICES.<br /> L’Utilisateur
                                    est en conséquence informé que SPB ne sera pas responsable des dommages résultant de : <br /> • l’utilisation faite par l’Utilisateur des Contenus mis
                                    à sa disposition au sein du Site et par le biais des Services et notamment de l’interprétation  qu’en fera <br /> l’Utilisateur et des conséquences de
                                    leur utilisation ;<br /> • l’enregistrement par l’Utilisateur d’informations incomplètes, erronées. SPB ne sera pas tenue responsable de toute
                                    inexécution ni tout retard dans l'exécution des Services, causé(s) par des évènements hors de son contrôle ou cas de force majeure au sens de la
                                    définition de l’article 1218 du Code civil.<br /> L’exécution des présentes CGU sera suspendue tant que durera le cas de force majeure et les délais
                                    d'exécution du Service seront prolongés d'autant.<br /> SPB s’efforcera dans la mesure du possible de faire cesser le cas de force majeure ou de trouve
                                    r une solution lui permettant d'exécuter ses obligations contractuelles malgré le cas de force majeure.

                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          7. LIMITES DE RESPONSABILITE CONCERNANT  LE SITE

                                    </span> </div>
                              <div className='textParagraph'>
                                    Les informations communiquées dans le Site sont fournies à titre général et documentaire. SPB décline toute responsabilité quant
                                    à l’utilisation, l’interprétation qui pourrait être faite des informations publiées dans le Site et leurs éventuelles conséquences.
                                    <br /> Il est précisé que SPB ne pourra être tenue pour responsable des dommages directs ou indirects, matériels ou immatériels,
                                    résultant de l’utilisation du Site ou de l’impossibilité d’y accéder, ou d’un mauvais fonctionnement, d’une interruption,
                                    d’un virus, ou encore d’un problème de connexion ou de système, faits de tiers, cas de force majeure ou cas fortuits,
                                    empêchant l'accès au Site ou à l’une de ses fonctionnalités.<br /> Le matériel de connexion au Site utilisé par l’Utilisateur
                                    demeure sous son entière responsabilité. Il doit prendre toutes les mesures appropriées pour protéger son matériel et ses propres données,
                                    notamment en cas d'attaques virales par Internet. Il est par ailleurs le seul responsable des sites et données qu’il consulte.<br />
                                    SPB ne pourra être tenue responsable en cas de poursuites judiciaires à l’encontre de l’Utilisateur :<br /> - du fait de l'usage du Site ou de tout
                                    Service accessible via Internet ; <br />- du fait du non-respect des présentes CGU.<br /> SPB n'est pas responsable des dommages causés à l’Utilisateur,
                                    à des tiers et/ou à leur équipement du fait de la connexion ou de l’utilisation du Site.
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          8. DROITS DE PROPRIETE INTELLECTUELLE
                                    </span> </div>
                              <div className='boxtextParagraph4'>
                                    <span >8.1 Contenus et Services du Site </span><br />
                              </div>
                              <div className='textParagraph'>
                                    L’ensemble des Contenus et Services accessible par le biais du Site et des Services sont protégés par des droits
                                    de propriété intellectuelle, détenus ou revendiqués <br /> par SPB, ses entités liées ou utilisés par SPB avec l’autorisation des titulaires de ces droits.
                                    Ces Services et Contenus sont exclusivement destinés à l’information <br /> et l’usage personnels de l’Utilisateur qui bénéficie d’un droit d’usage privé,
                                    non collectif et non exclusif.<br /> Sauf autorisation expresse et préalable de SPB, sont interdites toutes reproductions, représentations et utilisations
                                    par l’Utilisateur autres que celles visées <br />ci-dessus et notamment :<br /> • toute adaptation, mise à disposition du public à sa demande ou non,
                                    distribution, rediffusion sous quelque forme que ce soit, mise en réseau,<br /> communication publique, à titre gratuit ou onéreux, de tout ou partie des
                                    oeuvres, prestations, marques et tous éléments protégés ou susceptibles <br />de protection par le droit de la propriété intellectuelle reproduits au sein
                                    du Site et des Services ; <br />• tout lien, accès, modification, ajout, suppression qui porte sur le système de traitement automatisé de l’édition en
                                    ligne et modifie les conditions de publication<br /> ou la politique éditoriale. <br />Tout manquement de l’Utilisateur à ces obligations constituerait une
                                    contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.
                              </div>
                              <div className='boxtextParagraph4'>
                                    <span >8.2 Bases de données </span><br />
                              </div>
                              <div className='textParagraph'>
                                    Conformément aux
                                    dispositions relatives à la protection des bases de données, SPB est productrice et propriétaire de tout ou partie des bases de données <br />composant
                                    le Site.<br /> En accédant au Site, l’Utilisateur reconnaît que les données le composant sont légalement protégées ; il lui est interdit notamment
                                    d’extraire, réutiliser, stocker, reproduire, représenter ou conserver, directement ou indirectement, sur un support quelconque, par tout moyen
                                    et sous toute forme que ce soit, tout ou partie qualitativement ou quantitativement substantielle, du contenu des bases de données figurant au
                                    sein du Site auquel il accède ainsi que d’en faire l’extraction ou la réutilisation répétée et systématique de parties qualitativement et
                                    quantitativement non substantielles lorsque ces opérations excèdent manifestement les conditions normales.
                              </div>

                              <div className='boxtextParagraph4'>
                                    <span >8.3 Marques et signes distinctifs </span><br />
                              </div>
                              <div className='textParagraph'>
                                    Sauf précision contraire, toutes les marques utilisées au sein du Site sont la propriété de SPB ou de ses partenaires. <br />Sauf autorisation
                                    expresse et préalable de SPB et/ou de ses partenaires, tout usage de cette marque ainsi que toutes autres marques, figuratives ou non,
                                    <br /> visualisées appartenant à SPB ou à des tiers expose l’Utilisateur à des poursuites judiciaires.

                              </div>

                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          9. COOKIES
                                    </span> </div>
                              <div className='textParagraph'>
                                    Les informations relatives aux
                                    cookies utilisés au sein du Site sont précisées dans la Charte de Confidentialité publiée sur le Site.
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          10. LIENS HYPERTEXTES
                                    </span> </div>
                              <div className='textParagraph'>
                                    La mise en place
                                    par l’Utilisateur de tous liens hypertextes vers tout ou partie du Site est strictement interdite.<br /> Toute information accessible via un lien vers
                                    d'autres sites n'est pas sous le contrôle de SPB, qui décline toute responsabilité quant à leur contenu.
                              </div>
                              <div className='boxtextTitre1'>
                                    <span className='textTitre1'>
                                          11. DONNEES PERSONNELLES DE L’UTILISATEUR
                                    </span> </div>
                              <div className='boxtextparagh3'>
                                    <div className='textParagraph'>
                                          Les données communiquées à SPB dans le cadre de l'utilisation du Site font l'objet de traitements au sens et dans le respect de la
                                          loi du 6 janvier 1978 telle que <br /> modifiée par la loi du 20 juin 2018 dite "Informatique et Libertés", conforme au Règlement
                                          Général sur la Protection des Données personnelles. Dans ce cadre, <br />les responsables de traitement sont SPB et l’assureur
                                          concerné par la réclamation. Toutes les informations concernant le traitement des données de l’Utilisateur, <br /> ses finalités,
                                          les droits de l’Utilisateur ainsi que les obligations de SPB, sont détaillés dans la Charte de Confidentialité publiée sur le Site.
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}


export default ConditionsGenerales