import { RootState } from "config/reducers";
import { useAppDispatch } from "config/store";
import { getRecaptchaPublicKey } from "modules/config/store/config.selectors";
import { Reclamation } from "modules/reclamation/model/reclamation";
import { firstReclamationStep, majReclamation, previousReclamationStep } from "modules/reclamation/service/reclamation.service";
import { FormulaireStep } from "modules/reclamation/ui/components/formulaire-step";
import React, { useEffect } from "react"
import { useSelector } from "react-redux";

import './formulaire.css'

export const Formulaire = () => {

    const dispatch = useAppDispatch();

    const step = useSelector((state: RootState) => state.reclamation.step);
    const inProgress = useSelector((state: RootState) => state.reclamation.inProgress);
    const reclamation = useSelector((state: RootState) => state.reclamation.reclamation);
    const recaptcha = useSelector(getRecaptchaPublicKey);
  
    
    useEffect(()=> {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    },[step]);

    useEffect(()=> {
        dispatch(firstReclamationStep());
    },[]);

    const previousStep = () => {
        dispatch(previousReclamationStep());
    }

    const submit = (reclamation:Reclamation) => {
        dispatch(majReclamation(reclamation));
    }


    return (
        <FormulaireStep  inProgress={inProgress} step={step} reclamation={reclamation} recaptcha={recaptcha} cancel={() => previousStep()} submit={submit} />
    )
}