import React, { useEffect, useState } from 'react';
import './spb-text-area.css';
export interface ITextAreaProps {
  name: string;
  value?: any;
  initialValue?: any;
  error?: string;
  autoComplete?: string;
  placeholder?: string;
  disabled?: boolean;
  touched?: boolean;
  handleChange?: any;
  row?: any;
  limit?: any;
}


export const SpbTextArea: React.FC<ITextAreaProps> = ({ value,name, placeholder, initialValue, autoComplete, handleChange, disabled, touched, row, limit, error }: any) => {

  const [state, setState] = useState({
    touched: false
   });

   useEffect(()=> {
    if (touched !== undefined) {
      setState({
        touched
      })
    }
   }, [touched])

  const onChange = (e: React.ChangeEvent<any>) => {
    handleChange(e);
    
    setState({
      touched:true
    })
    
  }

  return (
    <div className="form-group">
      <textarea
        data-testid={name} 
        name={name}
        rows={row}
        value={value}
        className='textArea'
        placeholder={placeholder}
        autoComplete={autoComplete}
        defaultValue={initialValue}
        onChange={onChange}
      ></textarea>
      {state.touched && error && <span className='non-valid'>{error}</span>}

    </div>

  );


}
