import axios, { AxiosResponse } from 'axios';
import { Reclamation } from '../model/reclamation';

export interface IReclamationApi {
  postReclamation(reclamation:Reclamation): Promise<AxiosResponse<any>>;
}

class ReclamationApi implements IReclamationApi {

  

  async postReclamation(reclamation:Reclamation): Promise<AxiosResponse<any>> {

    const formData = new FormData();
   

    let recla = {...reclamation};


    //hack: ugly
    if (reclamation.relationSPB.contractSociete === '') {
      let relationSpb = {...reclamation.relationSPB};
      delete relationSpb.contractSociete;
      recla.relationSPB = {...relationSpb};
    }
    const relationSpb = new Blob([JSON.stringify(recla.relationSPB)], {
      type: 'application/json'
    });
    formData.append("relationSPB", relationSpb);
    const informations = new Blob([JSON.stringify(recla.informations)], {
      type: 'application/json'
    });
    formData.append("informations", informations);
    formData.append("declaration", recla.declaration.textDecla);
    formData.append("recaptcha", recla.declaration.recaptcha);
    if (recla.declaration.filesUpload) {
      for (let i = 0; i <= recla.declaration.filesUpload.length; i++) {
        formData.append(`files`, recla.declaration.filesUpload[i]);
      }
    }

    

    return await axios({ 
      
      method: "post",
      url: "/api/reclamation",
      data: formData,
      headers: { "Content-Type": "multipart/form-data"}
    });
    
    
    
  }

}

const reclamationApi = new ReclamationApi();

export { reclamationApi };