import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reclamation } from '../model/reclamation';

export enum TypeStep {
  RelationSPB=0,
  Informations=1,
  Reclamation=2
}

// Define the initial state using that type
export const initialState = {
  step: 0 as TypeStep,
  inProgress: false,
  reclamation: {
    relationSPB: {
      numAdhesion: '',
      numSinistre: '',
      nomContrat: '',
      contractSociete: ''
    },
    informations: {
      lastname: '',
      firstname: '',
      email: '',
      rue: '',
      codePostal: '',
      numTel: '',
      ville: '',
      pays:''
    },
    declaration: {
      textDecla: '',
    }
  } as Reclamation
}

export type ReclamationState = typeof initialState;

export const reclamationSlice = createSlice({
  name: 'reclamation',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    firstStep: (state)  => {
      state.step = TypeStep.RelationSPB;
      state.reclamation = {...initialState.reclamation}
    },
    progressing: (state,action: PayloadAction<boolean>)  => {
      state.inProgress = action.payload;
    },
    nextStep: (state)  => {
      if (state.step<2) state.step ++;
    },
    previousStep: (state)  => {
      if (state.step>0) state.step --;
    },
    updateReclamation:(state,action: PayloadAction<{reclamation:Reclamation}> )  => {
      state.reclamation = { ...state.reclamation,...action.payload.reclamation}
    }
  }
  
})

export const reclamationActions = reclamationSlice.actions

export default reclamationSlice.reducer