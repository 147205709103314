import classNames from 'classnames';
import React, { ChangeEvent, DragEventHandler, useState } from 'react'
import { InputFiles } from 'typescript';
import { CloudUpload } from '../spb-icons/cloud-upload';
import './spb-dropzone.css'

export interface ISpbDropzoneProps {
  name: string;
  onFilesAdded?: any;
}

export const SpbDropzone:React.FC<ISpbDropzoneProps>=({name, ...props})=>{

   const fileInputRef = React.createRef<HTMLInputElement>();

   const [state, setState] = useState({
    hightlight: false
   });

   const openFileDialog = () => {
      fileInputRef.current!.click();
  }

  const onDragOver:DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();
  
    setState({ hightlight: true });
  }

  const onDragLeave:DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();
  
    setState({ hightlight: false });
  }

  const onDrop:DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault();

    const files = evt.dataTransfer.files;
    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }

    setState({ hightlight: false });
  }

  const onFilesAdded = (evt:ChangeEvent<HTMLInputElement>) => {

    const files = evt.target.files;
    if (props.onFilesAdded && files) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
  }

  const fileListToArray = (list:FileList) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list[i]);
    }
    return array;
  }

  return (
  
      <div data-testid={"spb-dropzone"} className={classNames("dropzone", state.hightlight ? "highlight" : "")}
        onClick={openFileDialog}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        style={{ cursor: "pointer" }}

      >
        <CloudUpload className="icon"/>
        <input
          data-testid={"spb-dropzone-file"}
          ref={fileInputRef}
          className="fileInput"
          type="file"
          multiple
          onChange={onFilesAdded}
        />
      
      </div>
      
    )
}